.sales-report-chart-title {
  font-size: 12pt;
  font-weight: bold;
  margin: 40px 0 20px 0;
  text-align: center;
}

.sales-report-summary {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 40px 0 20px 0;
}

.sales-report-summary .list {
  display: flex;
  justify-content: space-between;
}

.sales-report-summary .list .label {
  font-size: 10pt;
  text-align: right;
  width: 84px;
}

.sales-report-summary .list .value {
  font-size: 10pt;
  font-weight: bold;
  text-align: right;
}
